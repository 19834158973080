import React from 'react'

function M220() {
  return (
    <div className={`bg-[url('./assets/Picture8.png')] bg-no-repeat bg-cover w-full h-screen`}>
      <img src={require('../assets/Picture5.png')} className='md:w-[350px] md:pt-14 mx-auto w-[250px] pt-28' />

      <div className='flex justify-between items-start md:p-20 md:px-28 p-40 px-4'>
        <a href="https://drive.google.com/file/d/1PEZ6N6NfmZhEkVLgfjmPn2-sq317l0wl/view?usp=sharing">
          <img src={require('../assets/Listern Here.png')} className='md:w-72 w-36' />
        </a>
        <a href="https://drive.google.com/file/d/121erMI-KwWLf_3vxpYcY1e3klhygUnVK/view?usp=drive_link">
          <img src={require('../assets/ViewVideo.png')} className='md:w-72 w-36' />
        </a>
      </div>
    </div>
  )
}

export default M220
