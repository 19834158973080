import React from 'react'

function ContactUs() {
  return (
    <div className={`bg-[url('./assets/Picture2.jpg')] w-full bg-cover bg-no-repeat bg-center h-screen relative overflow-hidden`}>
        <img src={require('../assets/Picture3.png')} className='w-full md:h-full absolute md:-top-[80%] -top-[300px] h-auto'/>
        <div className='flex justify-center items-center flex-col w-full h-full md:gap-16 gap-8 px-4'>
                <span className='flex flex-col gap-2'>
                    <h2 className='text-white md:text-[35px] text-lg font-extrabold text-center'>Contact Us at </h2>
                    <h2 className='text-white md:text-xl text-base font-semibold text-center lowercase'>Platinumvk@platinumo2.com </h2>
                </span>
                    <h2 className='text-white md:text-3xl text-lg font-extrabold text-center'>Book Your Free 15 Minute Consultation <a target='_black' href="https://calendly.com/d/cpzm-7p6-ybp/one-off-meeting" className="text-[#0b76a0]">Here</a> </h2>
                    <h2 className='text-white md:text-3xl text-lg font-extrabold text-center'>Book Your Full Deposit Consultation <a target='_black' href="https://calendly.com/m2-20/55min" className="text-[#c49500]">Here</a> </h2>
                <span className='flex flex-col gap-2'>
                    <h2 className='text-white md:text-3xl text-lg font-extrabold text-center'>Call Us at </h2>
                    <h2 className='text-white md:text-2xl text-base font-semibold text-center lowercase'>404-937-4060 </h2>
                </span>
        </div>
        <img src={require('../assets/Picture4.png')} className='md:w-[40%] w-[500px] absolute md:-bottom-[70%]  md:left-[30%] bottom-[-280px]'/>
    </div>
  )
}

export default ContactUs
