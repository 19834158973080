import React from 'react'
import { Link } from 'react-router-dom'
import Social from './Social'

function SideBar({ setNewsLetter, setDrawer }) {
    return (
        <div className='w-full md:h-full bg-[#191919] md:rounded-2xl flex flex-col px-4 py-4 gap-2 relative  overflow-y-scroll h-screen overflow-scroll sidebar'>

            <Link onClick={()=>setDrawer(false)} to={'/about-m2-20'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Group 5.png')} className='md:w-7 w-6' />
                About The M2-20
            </Link>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://drive.google.com/file/d/1GX-phGeTzHJ0L43kYj7owR7lPDmLs_cL/view?usp=drive_link'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group.png')} className='md:w-7 w-6' />
                View Video
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://drive.google.com/file/d/1dvwrAYUxjh0U0AUYac0moL0VMCDLWsxM/view?usp=drive_link'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group1.png')} className='md:w-7 w-6' />
                Download Brochure
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://calendly.com/m2-20/55min?month=2024-07'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group2.png')} className='md:w-7 w-6' />
                Book Your Consultation
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://buy.stripe.com/4gw6sk6426kR2bufYY'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group4.png')} className='md:w-7 w-6' />
                Buy Now
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://nowpayments.io/payment/?iid=5956942317'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group8.png')} className='md:w-7 w-6' />
                Buy With Crypto
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://platinumo2.com/'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/platinumo2 logo 1.png')} className='md:w-7 w-6' />
                Platinum O2.com
            </a>

            <Link onClick={()=>setDrawer(false)} to='/m2-20' className="flex justify-center mt-2">
                <img src={require('../assets/image 1 (1) 1.png')} className="w-[85%]" />
            </Link>

            <span onClick={()=>setDrawer(false)} className='flex md:hidden'>
                <Social />
            </span>
            <div onClick={() => {setDrawer(false); setNewsLetter(true); }} className='cursor-pointer md:mt-0  mt-5 flex md:justify-center items-center gap-2 rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white md:absolute md:bottom-2 left-[35%]'>
                <img src={require('../assets/Group 6.png')} className='md:w-7 w-6' />
                Sign up
            </div>
        </div>
    )
}

export default SideBar
