import React, { useEffect, useState } from 'react'
import SideBar from '../components/SideBar'
import Navbar from '../components/Navbar'
import NewsLetterPopup from '../components/NewsLetterPopup'

function Home() {
    const [newsLetter, setNewsLetter] = useState(false)
    const [drawer, setDrawer] = useState(false)
    const menuRef = React.useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setDrawer(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
    return (
        <div className='bg-[#0D0D0D] md:px-6 md:py-4 h-screen overflow-hidden flex justify-start items-start gap-4 px-2 py-2'>
            <div ref={menuRef} className={`md:w-1/3 md:h-full md:flex md:static absolute  ${drawer ? 'left-0': '-left-[500px]'} z-[9999] w-[70%] overflow-hidden transition-all ease-in duration-150`}>
                <SideBar setNewsLetter={setNewsLetter} setDrawer={setDrawer}/>
            </div>

            <div className='w-full h-full flex flex-col gap-4'>
                <Navbar setDrawer={setDrawer}/>
                <div className='w-full h-full rounded-2xl overflow-hidden relative' style={{background:"linear-gradient(72deg, #313130 0%, #3c3c3a 50%)"}}>
                    <img src={require('../assets/bg.png')} className='select-none absolute md:w-full md:top-[-260px] left-[0px] z-0 sm:relative top-0 ' />
                    <div className='home-text md:flex hidden absolute adjust-top w-full right-0 h-[450px]'>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-40 right-1 -rotate-[19deg]'>
                            The M2-20 Blockchain Asset Generator pioneering product combines the strengths of Blockchain, Web3, and Al,crafting a
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-36 right-1 -rotate-[19deg]'>
                            comprehensive crafting a comprehensive mobile/ technology developed to serve businesses and individuals with
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-32 right-1 -rotate-[19deg]'>
                            cryptocurrency asset liquidity daily by mining multiple cryptocurrencies simultaneously, with transactional
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[120px] right-1.5 -rotate-[19deg]'>
                            sending of mined assets to multiple wallets if desired. This trailblazing solution offers key
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[108px] right-2 -rotate-[19deg]'>
                            benefits, including enhanced security, transparency, decentralization, and
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[102px] right-2.5 -rotate-[19deg]'>
                            versatility, all while generating consistent digital asset
                        </p>
                        <p className='text-white md:text-base text-sm text-right absolute bottom-[115px] right-3.5 -rotate-[19deg]'>
                            values for its users.
                        </p>
                    </div>
                    <div className='md:hidden flex absolute bottom-0 w-full right-0 h-[450px]'>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[110px] right-0.5 -rotate-[20deg]'>
                            The M2-20 Blockchain Asset Generator pioneering product combines the strengths of Blockchain, Web3, and
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[154px] right-0 -rotate-[20deg]'>
                        Al, crafting a comprehensive crafting a comprehensive mobile/ technology developed to serve businesses and 
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[198px] right-0 -rotate-[20deg]'>
                        individuals with cryptocurrency asset liquidity daily by cryptocurrency asset liquidity daily by cryptocurrency
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[242px] right-0 -rotate-[20deg]'>
                        asset liquidity daily by mining multiple cryptocurrencies simultaneously, with transactional sending of mined 
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[284px] right-0 -rotate-[20deg]'>
                        assets to multiple wallets if desired. This trailblazing solution offers key benefits, including enhanced 
                        </p>
                        <p className='text-white md:text-base text-sm  absolute text-justify top-[326px] right-0 -rotate-[20deg]'>
                        security transparency, decentralization, and versatility, all while generating consistent digital asset values for its users.
                        </p>
                    </div>

                   

                    <img src={require('../assets/Picture5.png')} className='md:w-[400px] w-[200px] absolute rotate-[-18deg] top-[120px] md:top-[200px] left-[25%] md:left-[30%] z-[50] drop-shadow-2xl flicker' />
                </div>
            </div>

            {newsLetter && <NewsLetterPopup setNewsLetter={setNewsLetter}/>}
        </div>
    )
}

export default Home
