import React from 'react'

function AboutM220() {
    return (
        <div className={`bg-[url('./assets/Picture9.png')] bg-no-repeat bg-cover w-full h-screen bg-center md:py-14 md:px-14 px-4 md:overflow-hidden overflow-y-scroll py-16`}>
            <p className='about text-white/85 md:text-2xl text-sm font-extrabold text-center'>
                The M2-20 Blockchain Asset Generator pioneering product combines the strengths of Blockchain, Web3, and AI, crafting a comprehensive mobile technology developed to serve businesses and individuals with cryptocurrency asset liquidity daily by mining multiple cryptocurrencies simultaneously, with transactional sending of mined assets to multiple wallets if desired. This trailblazing solution offers key benefits, including enhanced security, transparency, decentralization, and versatility, all while generating consistent digital asset values for its users. Moreover, we harness the power of AI to automate tasks, predict outcomes, and generate consistent crypto-assets, empowering users to unlock new levels of efficiency, speed, and digital asset liquidity. The M2-20 Cryptocurrency Asset Generator is the world’s first 100 % portable 2GH/s mining complex now available for purchase here at Cryptopaywelldaily.com.
            </p>
            <p className='about text-white/85 md:text-2xl text-lg font-medium text-center mt-16'>
                What are the greatest advantages and benefits of the M2-20 Blockchain Asset Generator?
            </p>
            <ul className='flex flex-col mt-2 list-disc justify-center items-center'>
                    <li className='about text-white/85 md:text-2xl text-sm font-medium text-center'>
                    The M2-20 consistent cryptocurrency output of between six to twelve dollars per hour of digital assets is a strong foundation for improving one’s quality of life
                    </li>
                    <li className='about text-white/85 md:text-2xl text-sm font-medium text-center'>
                    Eight percent staking rewards of crypto assets monthly add to your income bottom line
                    </li>
                    <li className='about text-white/85 md:text-2xl text-sm font-medium text-center'>
                    Durability, Quality, Dependability and security are all features of the M2-20 Blockchain Asset Generator
                    </li>
                    <li className='about text-white/85 md:text-2xl text-sm font-medium text-center'>
                    The Executive Package adds exponentially more value for the user who chooses the entrepreneur path for greater returns
                    </li>
                    <li className='about text-white/85 md:text-2xl text-sm font-medium text-center'>
                    The M2-20 is one hundred percent portable with worldwide compatibility use 
                    </li>
                    <li className='about text-white/85 md:text-2xl text-sm font-medium text-center'>
                    The M2-20 enable users to live anywhere in the world that offers a higher quality of life while reducing ones living expenses dramatically
                    </li>
            </ul>
        </div>
    )
}

export default AboutM220
