import React, { useState } from 'react'
import { RxCross2 } from 'react-icons/rx'
import { toast } from 'react-toastify'

function NewsLetterPopup({ setNewsLetter }) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const Submit = (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            // fetch('https://sheetdb.io/api/v1/lg1dt20nxs4sd', {
            fetch('https://sheetdb.io/api/v1/5hohmdcb0d640', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: [
                        {
                            'Email': email,
                            'Date': `${new Date().toISOString()}`
                        }
                    ]
                })
            })
                .then((response) => {
                    toast.success("Email sent successfully.")
                    setNewsLetter(false)
                })
                .then((data) => {console.log(data)})
                .catch((e) => {
                    console.log(e);
                    toast.error("Some error occured.")
                })
                .finally(()=> {
                    setLoading(false)
                })

        } catch (error) {
            console.log(error);
        }
    }
  return (
    <div className='fixed top-0 left-0 w-full h-screen bg-black/95 z-[10000000000] flex justify-center items-center'>
        <div className='w-[90%] md:w-1/3 bg-[#1f1f1f] px-4 py-5 rounded-2xl'>
            <div className='flex justify-between items-baseline'>
                <h2 className='text-xl font-bold text-white'>Newsletter</h2>
                <span className='flex justify-center items-center bg-black/50 cursor-pointer rounded-md p-2' onClick={()=>setNewsLetter(false)}>
                    <RxCross2 className='text-lg text-[#b59004]'/>
                </span>
            </div>
            <p className='text-white text-sm md:text-base leading-6 mt-2'>Stay ahead in the crypto revolution — sign up now to discover how the M2-20 Blockchain Asset Generator can boost your digital wealth daily!</p>
            <form onSubmit={Submit} className='flex flex-col gap-3 mt-4'>
                    <input
                        onChange={(e) => setEmail(e.target.value)}
                    type='email' required className='focus:border-[#b59004] transition-all ease-in duration-100 outline-none border-[1px] border-white/40 rounded-lg w-full text-gray-200 bg-transparent px-4 py-3' placeholder='Enter your email'/>
                    <button disabled={loading} className='py-3 border-[1px] border-[#b59004] bg-[#b59004] text-white hover:bg-[#b58f048b] text-base rounded-lg transition-all ease-in duration-100'>Sign Up</button>
            </form>
        </div>
    </div>
  )
}

export default NewsLetterPopup
